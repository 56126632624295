@use "@carbon/react";

.sidebar {
  color: #0f62fe;
}

.question-item {
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

.question-item:hover {
  background-color: #e5e5e5;
  text-decoration: underline;
  cursor: pointer;
}
.cds--accordion__content {
  padding-right: 0px;
}

.accordion-item-sidebar {
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
}

.accordion-item-sidebar .cds--accordion__title {
  font-weight: 600;
  color: #525252;
}

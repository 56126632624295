.instructions {
  padding-bottom: 15px;
  color: #525252;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.instructions-header {
  padding-top: 10px;
  padding-bottom: 15px;
  color: #000000;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  text-align: left;
}

.guide-button {
  width: 126px;
  height: 40px;
  text-align: center;
  font-size: 14x;
  line-height: 18px;
  padding-right: 10px !important;
}

.login {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 66px);
  justify-content: center;
  align-items: center;
}

.login-btn-box {
  display: flex;
  flex-direction: row;
  background-color: #0f62fe;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 111.22px;
}

.login-btn {
  text-align: start;
  text-indent: 10px;
  height: 42px;
  background-color: #0f62fe;
  color: white;
  border: 0px;
  cursor: pointer;
  font-size: 12.25px;
}

.login-arrow-margin {
  cursor: pointer;
  margin-right: 14px;
}

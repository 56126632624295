.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-inner {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }
  
  .popup-inner button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5rem;
  }
  
  .team {
    display: flex;
    flex-wrap: wrap;
    // gap: 10px;
    justify-content: center;
    margin: 3em;
    width:"100%"
  }
  
  .team-member {
    text-align: center;
    padding: 1em;
  }
  
  .team-member img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
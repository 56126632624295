
.App {
  /* text-align: center; */
  /* padding: 0px; */
  background-color: #f4f4f4
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

caption {
  font-size: 20px;
  padding: 10px;
  /* padding-bottom: 10px;
  margin-bottom: 10px; */
}

.container {
  /* padding-bottom: 10px; */
  border-color: #2a2a2a;
  border: '2px solid red';
  max-width: 1000px !important;
  
}

.header {
  background-color: #000000;
  color: white;
  padding: 10px;
  text-align: center;
  /* height: 110px; */

}

.headerTitle {
  color: #fff;
  padding-top: 100px;
  /* padding-left: 20px; */
  /* float: left; */
  font-size: 20px;
  font-weight:600;
}

.chatbox {
  background-color: #f4f4f4
}

.sidebar {
  background-color: #ffffff;
  color: white;
  padding: 10px;
  text-align: center;
  max-height: calc(100vh - 278px);
  overflow-y: auto;
}

@media (max-width: 1214px) {
  .sidecol {
    width: 100% !important; /* Make the width equal to the width of the page */
  }
}
.sidecol {
  max-height: calc(100vh - 85px);
  background-color: #ffffff;

}

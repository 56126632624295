@use "@carbon/react";
/* Import Google font - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
Container {
  background: #e3f2fd;
}
.chatbot-toggler {
  position: fixed;
  bottom: 30px;
  right: 35px;
  outline: none;
  border: none;
  height: 50px;
  width: 50px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #724ae8;
  transition: all 0.2s ease;
}
.show-chatbot .chatbot-toggler {
  transform: rotate(90deg);
}
.chatbot-toggler span {
  color: #fff;
  position: absolute;
}
.chatbox {
  //scrollbar-color: rebeccapurple green;
  scrollbar-color: darkgrey #e3f2fd;
}
.chatbot::-webkit-scrollbar-track {
  background: blue; /* color of the tracking area */
}

.chatbox::-webkit-scrollbar-track {
  border: 10px solid rgb(244, 244, 244);
}

.chatbox::-webkit-scrollbar-thumb {
  border: 10px solid rgb(244, 244, 244);
}

.chatbot-toggler span:last-child,
.show-chatbot .chatbot-toggler span:first-child {
  opacity: 0;
}
.show-chatbot .chatbot-toggler span:last-child {
  opacity: 1;
}
.chatbot {
  height: 100%;
}
.show-chatbot .chatbot {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
}

.chatbot .chatbox {
  overflow-y: auto;
  height: calc(100vh - 85px);
  padding: 30px 20px 100px;
}
.chatbot :where(.chatbox, textarea)::-webkit-scrollbar {
  width: 6px;
}
.chatbot :where(.chatbox, textarea)::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 25px;
}
.chatbot :where(.chatbox, textarea)::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 25px;
}
.chatbox .chat {
  display: flex;
  list-style: none;
}
.chatbox .outgoing {
  margin: 20px 0;
  margin-left: 80px;
  justify-content: flex-end;
}
.chatbox .incoming span {
  width: 32px;
  height: 32px;
  color: #f2f3f2;
  cursor: default;
  text-align: center;
  line-height: 32px;
  align-self: flex-end;
  background: #fff;
  border-radius: 4px;
  /* margin: 0 10px 7px 0; */
}

.domain-button {
  margin-left: 20px;
  border-width: 1px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 100px;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  background-color: rgb(215, 215, 215);
  color: #3b3b3b;
}

.domain-button:hover {
  background-color: rgb(200, 200, 200);
}
.domain-button:visited {
  background-color: #979797;
}

.domain-button-active {
  background-color: #848484 !important;
  color: white;
}

.domain-button:disabled,
.domain-button:disabled:hover {
  cursor: not-allowed;
  background-color: rgb(200, 200, 200);
}

.domain-container {
  padding-left: 80px;
  padding-top: 5px;
  padding-right: 15px;
}

.chatbox .chat p {
  white-space: pre-wrap;
  padding: 12px 16px;
  border-radius: 10px 10px 10px 10px;
  max-width: 100%;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  background: #323232;
}
.chatbox .incoming p {
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 10px;
}
.chatbox .chat p.error {
  color: #721c24;
  background: #f8d7da;
}
.chatbox .incoming p {
  color: #000;
  background: #f2f3f2;
}
.chatbot .loading-text {
  display: flex; 
  // gap: 1em; 
  background-color: white !important;
  position: fixed;
  bottom: 4em; 
  left: 4em; 
  width: 18%; 
  border-radius: 10px;
  z-index: 99998; 
  // padding-bottom: 1em; 
  // padding-top: 0.7em
  opacity: 0.9;
  padding: 1em;
}
.chatbot .chat-input {
  display: flex;
  gap: 5px;
  position: absolute;
  bottom: 0;
  justify-content: center;
  width: 100%;
  background: #f4f4f4;
  z-index: 99999;
  /* border-top: 1px solid #ddd; */
}
textarea {
  height: 55px;
  width: 100%;
  border: 1px solid;
  border-radius: 10px;
  outline: none;
  resize: none;
  max-height: 180px;
  padding: 15px 15px 15px 15px;
  font-size: 1px;
  font-style: normal;
  font-weight: 400;
}
.chat-input span {
  align-self: flex-end;
  color: #8b1d41;
  cursor: pointer;
  height: 55px;
  display: flex;
  align-items: center;
  visibility: hidden;
  font-size: 2.35rem;
}
.chat-input textarea:valid ~ span {
  visibility: visible;
}

@media (max-width: 490px) {
  .chatbot-toggler {
    right: 20px;
    bottom: 20px;
  }
  .chatbot {
    right: 0;
    bottom: 0;
    height: 100%;
    border-radius: 0;
    width: 100%;
  }
  .chatbot .chatbox {
    height: 100%;
    padding: 25px 15px 100px;
  }
  .chatbot .chat-input {
    padding: 5px 15px;
  }
  .chatbot header span {
    display: block;
  }
}

.circle {
  border-radius: 50%;
  width: 50px;
  height: 44px;
  padding: 10px;
  background: #fff;
  border: 1px solid #000000;
  color: #8b1d41;
  text-align: center;
  font: 20px Arial, sans-serif;
  text-align: center;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.placeholder {
  padding: 25px;
  background: #f4f4f4;
  color: #2a2a2a;
  text-align: center;
  font: 20px Arial, sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.accordion {
  background-color: #f2f3f2;
  color: #444;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  text-align: left;
  border-top: 1px solid #d9d9d9;
  outline: none;
  transition: 0.4s;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10 10;
  font-size: 16px;
  line-height: 24px;
  color: #8b1d41;
  text-align: left;
  background-color: #f2f3f2;
  border: 0;
  border-radius: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.accordion-button {
  font-size: medium;
  color: #65081f;
}

.accordion-button:focus {
  z-index: 3;
  color: #65081f;
  border-color: #f2f3f2;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-button:not(.collapsed) {
  color: #65081f;
  background-color: #f2f3f2;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.active,
.accordion:hover {
  background-color: #f2f3f2;
}

accordion-body {
  background-color: #f2f3f2;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: 1px solid #f2f3f2;
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: 10px;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: #0070e0;
  background-color: #ffffff;
  border-bottom: none;
  font-size: 10px;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: #000000;
}

#box {
  position: relative;
}

#iconThumbUp {
  background-color: transparent;
  color: rgb(71, 170, 71);
  /* padding: 5px; */
  width: 30px;
  height: 20px;
  position: absolute;
  right: -10px;
  bottom: -1px;
  border-radius: 50px;
  font-size: 20px;
}
#iconThumbDwn {
  background-color: transparent;
  color: rgb(226, 47, 6);
  /* padding: 5px; */
  width: 30px;
  height: 20px;
  position: absolute;
  right: -10px;
  bottom: -1px;
  border-radius: 50px;
  font-size: 20px;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px 20px;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.modal-body {
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #eeeeee;
}

textarea,
input,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
}

textarea {
  min-height: 80px;
  resize: vertical;
}

button {
  align-self: flex-end;
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.source-accordion {
  list-style-type: none !important;
  font-size: 16px !important;
}

.source-accordion-item {
  border-block: unset !important;
}

.cds--accordion__title {
  font-size: 16px !important;
}

.cds--accordion__content > p {
  font-size: 16px !important;
}

.source-accordion-item .cds--accordion__title {
  padding-bottom: 10px;
  padding-top: 10px;
}

svg.icon-enabled {
  fill: white !important;
}

.button-send-disabled {
  background: #ffffff !important;
  height: 50px;
  margin-left: -5px !important;
}

.button-send-enabled {
  background: #0f62fe !important;
  height: 50px;
  margin-left: -5px !important;
}

.button-send-enabled:hover {
  background: #0f4ec3 !important;
}
.feedback-button {
  padding: 0;
  margin-left: 20px;
  border-width: 0px;
  margin-top: 20px;
  border-radius: 100px;
  background-color: rgb(215, 215, 215);
  font-size: 16px;
  line-height: 24px;
  width: 100px;
  color: #000000;
  height: 32px;
}

.feedback-button:disabled {
  padding: 0;
  margin-left: 20px;
  border-width: 0px;
  margin-top: 20px;
  border-radius: 100px;
  background-color: rgb(200, 200, 200);
}

.feedback-button:active {
  padding: 0;
  margin-left: 20px;
  border-width: 0px;
  margin-top: 20px;
  border-radius: 100px;
  background-color: rgb(200, 200, 200) !important;
  font-size: 16px;
  line-height: 24px;
  width: 100px;
  color: #ffffff;
  height: 32px;
}

.visbility-hidden {
  visibility: hidden;
}
.feedback-button:active > .feedback-button-positive {
  fill: #0ba200;
}

.feedback-button:active > .feedback-button-negative {
  fill: rgb(144, 2, 2);
}
.feedback-button:hover > .feedback-button-positive {
  fill: #0ba200;
}

.feedback-button:hover {
  background-color: rgb(200, 200, 200);
}
.feedback-button:hover > .feedback-button-negative {
  fill: rgb(144, 2, 2);
}

.feedback-button-negative-active {
  fill: rgb(144, 2, 2) !important;
}

.feedback-button-positive-active {
  fill: #0ba200 !important;
}
